
<template>
  <div>
    <OrderSwiper
      :data="orderSwiperData"
      type="painter"
      @takeOrder="takeOrder"
      :loading="loading"
    ></OrderSwiper>
  </div>
</template>

<script>
import OrderSwiper from "../../components/OrderSwiper.vue";

export default {
  components: {
    OrderSwiper,
  },
  data() {
    return {
      orderSwiperData: [],
      loading: false,
    };
  },
  mounted() {
    this.getProjectList();
  },
  methods: {
    getProjectList() {
      this.loading = true;
      this.$api.project.list().then((res) => {
        this.orderSwiperData = res.data;
        this.loading = false;
      });
    },
    takeOrder(project) {
      this.$api.project.take_order({ id: project.id }).then(() => {
        this.$router.push({
          name: "order_management",
          params: { project, orderManagementShow: true },
        });
      });
    },
  },
};
</script>
